const searchParams = new URLSearchParams(window.location.search);
console.log("searchParams", searchParams);
const token = searchParams.get("token");
const id = searchParams.get("id");
const ts = searchParams.get("ts");
console.log("token", token);
console.log("id", id);

//clear the local storage

function getConfig() {
  console.log("getConfig called");
  console.log("token", token);
  console.log("id", id);
  let url = `https://us-west-2.aws.data.mongodb-api.com/app/cetv-ads-mammoth-qtgpyqe/endpoint/config?token=${token}&id=${id}`;

  fetch(url, {
    method: "GET",
  })
    .then((response) => response.json())
    .then((data) => {
      console.log("data", data);
      document.getElementById("message").innerText = "Done. Please wait.";
      localStorage.setItem("config", JSON.stringify(data));
      console.log("config set");
      console.log(JSON.parse(localStorage.getItem("config")));
      setTimeout(() => {
        window.location.href = `/player?ts=${ts}`;
      }, 5000);
    })
    .catch((error) => {
      console.log("error", error);
      document.getElementById("message").innerText = "Error. Please check the token.";
    });
}

document.addEventListener("readystatechange", (event) => {
  if (event.target.readyState === "complete") {
    console.log("DOM is ready");
    getConfig();
  }
});
